<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    width="512"
    height="512"
  >
    <path
      d="M12,24c-1.539,0-2.99-.676-3.982-1.854-.095-.113-.219-.164-.376-.155-1.528,.126-3.039-.417-4.127-1.505-1.088-1.088-1.637-2.592-1.505-4.127,.013-.147-.043-.281-.155-.375-1.179-.993-1.854-2.445-1.854-3.983s.676-2.99,1.854-3.982c.113-.095,.169-.229,.156-.376-.132-1.535,.417-3.039,1.505-4.127,1.087-1.087,2.584-1.64,4.127-1.505,.145,.014,.281-.042,.376-.155,.992-1.179,2.443-1.854,3.982-1.854s2.99,.676,3.982,1.854c.096,.113,.22,.166,.376,.155,1.521-.13,3.039,.417,4.127,1.505,1.088,1.088,1.637,2.592,1.505,4.127-.013,.147,.043,.281,.155,.375,1.179,.993,1.854,2.445,1.854,3.983s-.676,2.99-1.854,3.982c-.113,.095-.169,.229-.156,.376,.132,1.535-.417,3.039-1.505,4.127-1.087,1.087-2.582,1.643-4.127,1.505-.144-.011-.281,.043-.376,.155-.992,1.179-2.443,1.854-3.982,1.854Zm-4.314-3.012c.425,0,.819,.183,1.098,.514,.801,.952,1.974,1.498,3.217,1.498s2.416-.546,3.217-1.498c.306-.364,.757-.55,1.228-.508,1.241,.105,2.455-.337,3.334-1.216s1.322-2.094,1.216-3.335c-.04-.474,.145-.92,.509-1.226,.951-.802,1.497-1.975,1.497-3.217s-.546-2.416-1.498-3.218c-.363-.305-.548-.752-.508-1.226,.106-1.241-.337-2.456-1.216-3.335-.88-.879-2.103-1.322-3.334-1.216-.48,.044-.921-.144-1.228-.508-.801-.952-1.974-1.498-3.217-1.498s-2.416,.546-3.217,1.498c-.307,.364-.762,.551-1.228,.508-1.243-.107-2.455,.337-3.334,1.216-.879,.879-1.322,2.094-1.216,3.335,.04,.474-.145,.92-.509,1.226-.951,.802-1.497,1.975-1.497,3.217s.546,2.416,1.498,3.218c.363,.305,.548,.752,.508,1.226-.106,1.241,.337,2.456,1.216,3.335,.879,.879,2.099,1.319,3.334,1.216,.044-.004,.087-.006,.13-.006Zm4.768-6.714l4.739-4.568c.199-.191,.205-.508,.014-.707-.192-.197-.508-.205-.707-.013l-4.746,4.574c-.585,.584-1.537,.584-2.134-.013l-2.278-2.117c-.202-.187-.519-.176-.707,.026s-.177,.519,.025,.707l2.266,2.104c.488,.488,1.13,.732,1.77,.732s1.275-.242,1.759-.726Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'badgeCheck'
};
</script>

<style lang="scss" scoped>
svg {
  fill: #704ad1;
  overflow: hidden;
  vertical-align: middle;
  transform: rotateZ(0deg);
  height: 25px;
  width: 25px;
}
</style>
